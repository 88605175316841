import { default as cookie_45policyR0Q3gHg5HMMeta } from "/opt/buildhome/repo/pages/cookie-policy.vue?macro=true";
import { default as dmcas5QN3G8e7vMeta } from "/opt/buildhome/repo/pages/dmca.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as legalCaKSmGXd4VMeta } from "/opt/buildhome/repo/pages/legal.vue?macro=true";
import { default as other_45siteslNJqLKtdwaMeta } from "/opt/buildhome/repo/pages/other-sites.vue?macro=true";
import { default as _91domain_93xTu30xcveBMeta } from "/opt/buildhome/repo/pages/posts/[domain].vue?macro=true";
import { default as additional_45boorusO45iRN2jZyMeta } from "/opt/buildhome/repo/pages/premium/additional-boorus.vue?macro=true";
import { default as backupNvLRcPxlPjMeta } from "/opt/buildhome/repo/pages/premium/backup.vue?macro=true";
import { default as dashboardrEXYVtwvhoMeta } from "/opt/buildhome/repo/pages/premium/dashboard.vue?macro=true";
import { default as forgot_45password3yS7DsJeDpMeta } from "/opt/buildhome/repo/pages/premium/forgot-password.vue?macro=true";
import { default as indexf1tumCiLvsMeta } from "/opt/buildhome/repo/pages/premium/index.vue?macro=true";
import { default as _91domain_93lQHbLYRMCyMeta } from "/opt/buildhome/repo/pages/premium/saved-posts/[domain].vue?macro=true";
import { default as sign_45inkI2PCyJboYMeta } from "/opt/buildhome/repo/pages/premium/sign-in.vue?macro=true";
import { default as tag_45collectionsqXoH8gIYzFMeta } from "/opt/buildhome/repo/pages/premium/tag-collections.vue?macro=true";
import { default as privacy_45policypnUDKF1OY5Meta } from "/opt/buildhome/repo/pages/privacy-policy.vue?macro=true";
import { default as settings4cDYwTsA31Meta } from "/opt/buildhome/repo/pages/settings.vue?macro=true";
import { default as terms_45of_45service4Xad5aMSOFMeta } from "/opt/buildhome/repo/pages/terms-of-service.vue?macro=true";
export default [
  {
    name: "cookie-policy",
    path: "/cookie-policy",
    component: () => import("/opt/buildhome/repo/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "dmca",
    path: "/dmca",
    component: () => import("/opt/buildhome/repo/pages/dmca.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/opt/buildhome/repo/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: "other-sites",
    path: "/other-sites",
    component: () => import("/opt/buildhome/repo/pages/other-sites.vue").then(m => m.default || m)
  },
  {
    name: "posts-domain",
    path: "/posts/:domain()",
    meta: _91domain_93xTu30xcveBMeta || {},
    component: () => import("/opt/buildhome/repo/pages/posts/[domain].vue").then(m => m.default || m)
  },
  {
    name: "premium-additional-boorus",
    path: "/premium/additional-boorus",
    meta: additional_45boorusO45iRN2jZyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/premium/additional-boorus.vue").then(m => m.default || m)
  },
  {
    name: "premium-backup",
    path: "/premium/backup",
    meta: backupNvLRcPxlPjMeta || {},
    component: () => import("/opt/buildhome/repo/pages/premium/backup.vue").then(m => m.default || m)
  },
  {
    name: "premium-dashboard",
    path: "/premium/dashboard",
    meta: dashboardrEXYVtwvhoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/premium/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "premium-forgot-password",
    path: "/premium/forgot-password",
    meta: forgot_45password3yS7DsJeDpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/premium/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "premium",
    path: "/premium",
    component: () => import("/opt/buildhome/repo/pages/premium/index.vue").then(m => m.default || m)
  },
  {
    name: "premium-saved-posts-domain",
    path: "/premium/saved-posts/:domain()",
    meta: _91domain_93lQHbLYRMCyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/premium/saved-posts/[domain].vue").then(m => m.default || m)
  },
  {
    name: "premium-sign-in",
    path: "/premium/sign-in",
    meta: sign_45inkI2PCyJboYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/premium/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "premium-tag-collections",
    path: "/premium/tag-collections",
    meta: tag_45collectionsqXoH8gIYzFMeta || {},
    component: () => import("/opt/buildhome/repo/pages/premium/tag-collections.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/opt/buildhome/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/buildhome/repo/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    component: () => import("/opt/buildhome/repo/pages/terms-of-service.vue").then(m => m.default || m)
  }
]